import React from "react";
import { useForm } from '@formspree/react';

const FORMSPREE_KEY = "xoqrzlrr";

const Contact = ()=> {

  const [state, handleSubmit] = useForm(FORMSPREE_KEY);

    return (
      <div className="contact section" id="Contact">
        <div className="container">
          <div className="section-head">
            <h2 className="text-center">Contacto</h2>
          </div>
          <form
            onSubmit={handleSubmit}
            name="contact"
            method="POST"
            data-netlify="true"
          >
            <div>
              <label>
                Nome: <input type="text" name="name" required />
              </label>
            </div>
            <div>
              <label>
                Email: <input type="email" name="email" required />
              </label>
            </div>
            <div>
              <label>
                Mensagem: <textarea name="message" required></textarea>
              </label>
            </div>
            <div>
              <button type="submit" disabled={state.submitting}>Enviar</button>
            </div>
          </form>
          {state && state.succeeded &&
          <p>Obrigado! A sua mensagem foi submetida com sucesso!</p>}
        </div>
      </div>
    );
}

export default Contact;